<template>
  <div id="app">
    <component :is="currentView" :currentView.sync="currentView"></component>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import SignIn from './components/SignIn.vue';
import Dashboard from './components/Dashboard.vue';
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyArUFCnF5z66jhBQPFnAeT-yxB4zToWnRQ",
  projectId: "am-client-bi-dashboard",
  appId: "1:797472842894:web:28e83caf7fe19afd1315df"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default {
  name: 'App',
  components: {
    SignIn,
    Dashboard
  },
  data() {
      return {
        currentView: ""
      }
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged(() => {
      firebase.auth().currentUser !== null ? this.currentView = "Dashboard" : this.currentView = "SignIn";
    });
  }
}
</script>

<style>
#app {
    display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100%;
}

html,
body {
  height: 100%;
}

</style>
