<template>
	<div class="dashboard">
		<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
			<a class="navbar-brand">BI Dashboard</a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="collapse navbar-collapse" id="navbarsExampleDefault">
				<ul class="navbar-nav mr-auto">

			<!--
					
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
					</li>
			-->
					<li v-if="clients.length > 1" class="nav-item dropdown">
						<div class="btn-group">
							<a class="nav-link dropdown-toggle" href="#" id="menu-clients" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Clients</a>
							<div class="dropdown-menu" aria-labelledby="menu-clients">
								<a v-for="item in clients" :key="item.name" class="dropdown-item" @click.prevent="changeClient(item.name)" href="">{{item.name}}</a>
							</div>
						</div>
					</li>
					<li class="nav-item active">
						<a class="nav-link">{{currentClient}}</a>
					</li>
					<li v-if="dashboards.length > 1" class="nav-item dropdown">
						<div class="btn-group">
							<a class="nav-link dropdown-toggle" href="#" id="menu-dashboards" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dashboards</a>
							<div class="dropdown-menu" aria-labelledby="menu-dashboards">
								<a v-for="item in dashboards" :key="item.name" class="dropdown-item" @click.prevent="changeDashboard(item.name)" href="">{{item.value.name}}</a>
							</div>
						</div>
					</li>
					<li class="nav-item active">
						<a class="nav-link">{{currentDashboardName}}</a>
					</li>
					<button v-if="dashboards.length > 1 && this.url != ''" @click="goHome" class="btn btn-primary my-2 my-sm-0" type="submit">Home</button>
				</ul>
				<div class="btn-group">
					<a class="nav-link dropdown-toggle navbar-text loggedin-user" id="menu-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">Signed in as <strong>{{userEmail}}</strong></a>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="menu-user">
						<a class="dropdown-item" href="" data-toggle="modal" data-target="#modal-reset-password">Reset password</a>
					</div>
					</div>
				<button @click="signout" class="btn btn-primary my-2 my-sm-0" type="submit">Sign out</button>

			</div>
		</nav>

		<main role="main" class="dashboard">
			<div class="starter-template">
				<iframe v-if="url != ''" v-bind:src="url" width="100%" height="100%" marginheight="0" marginwidth="0" frameborder="0"></iframe>
				<div width="100%" v-if="url == ''">
					<img class="hero" v-if="heroImage != ''" :src="require(`@/assets/img/${heroImage}`)">
					<div class="content-home">
						<b-container fluid>
							<b-row>
								<b-col>
									<h2>Dashboard Considerations</h2>
									<ul>
										<li>All visualizations and tables will exclude current month.</li>
										<li>Data refreshes throughout the day - in some cases every 15 minutes. Data will automatically update, no need to refresh the page.</li>
										<li>Attributions windows are in play for Facebook/Instagram and paid search, so last month's numbers will slowly change as data is gathered.</li>
										<li>All data for last month will switch on the 1st of each month, and each tactic will have a 3-month look-back window for future reference.</li>
										<li>The entire dashboard is interactive. You may click on a specific market or ownership group to narrow performance metrics as you wish. Also hover your mouse or cursor over the title to view more information about that chart, or hover over an element on the graph to highlight any specific data point.</li>
									</ul>
								</b-col>
								<b-col>
									<h2>Confidentiality Notice</h2>
									<p>This electronic transmission/dashboard (and/or the documents accompanying it) contains confidential information belonging to Palm Beach Tan, Inc. It is legally protected, privileged, confidential, subject to copyright or constitute a trade secret. If you are not the intended recipient you are hereby notified that any dissemination, copying or distribution of this message, or files associated with this message, is strictly prohibited. If you have received this communication in error, please notify Palm Beach Tan, Inc. immediately and destroy the original message.</p>	
								</b-col>
							</b-row>
						</b-container>



					</div>
				</div>
			</div>
		</main>	

		<div class="modal fade" id="modal-reset-password" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit.prevent="resetPassword">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">Reset Password</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<input id="resetPassword" type="password" v-model="passwordReset" class="form-control" placeholder="New password" required autofocus>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Reset password</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-info" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">	
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">Information</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						{{infoMessage}}
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import firebase from 'firebase/app';
import SignIn from './SignIn.vue';
import $ from 'jquery'

export default {
	name: 'Dashboard',
	data() {
		return {
			url: "",
			clients: [],
			dashboards: [],
			currentClient: "",
			currentDashboard: "",
			currentDashboardName: "",
			heroImage: "",
			userEmail: "",
			passwordReset: "",
			infoMessage: ""
		}
	},
	methods: {
		fbDBDataToKeyValueObjArray: function(obj) {
			const returnArr = [];
			obj.forEach(function(child) {
				const item = {};
				item.name = child.key;
				item.value = child.val();
				returnArr.push(item);
			});
			return returnArr;
		},
		signout: function() {
			firebase.auth().signOut().then(()=>{
				this.url = "";
				this.clients = [];
				this.dashboards = [];
				this.currentClient = "";
				this.currentDashboard = "";
				this.currentDashboardName = "";
				this.heroImage = "";
				this.userEmail = "";

				this.$emit('update:currentView', SignIn);
			})
			.catch(()=>{});
		},
		goHome: function() {
			this.setClient(this.currentClient);
		},
		setClient: function(client) {
			firebase.database().ref('clients/' + client).get().then((data) => {
				this.currentClient = client;
				this.currentDashboard = "";
				this.currentDashboardName = "";
				this.url = "";
				this.heroImage = data.val().hero_image;
				this.dashboards = [];
				firebase.database().ref("clients/" + client + "/dashboards").get().then((data) => {
					var dashboards = this.fbDBDataToKeyValueObjArray(data);
					dashboards.forEach((dashboard)=>{
						this.dashboards.push(dashboard);
					})
				});

			});
		},
		changeClient: function(client) {
			if (client !== this.currentClient) {
				//--- Make sure user has access to client.
				var user = firebase.auth().currentUser;
				if (user !== null){
					firebase.database().ref("users/" + user.uid + "/clients/" + client).get().then(()=>{
						this.setClient(client);
					});
				}
			}
		},
		changeDashboard: function(dashboard) {
			if (dashboard !== this.currentDashboard) {
				firebase.database().ref("clients/" + this.currentClient + "/dashboards/" + dashboard).get().then((data) => {
					this.currentDashboard = dashboard;
					var db = this.fbDBDataToKeyValueObjArray(data); 
					this.currentDashboardName = db[0].value;
					this.url = db[1].value;
				});
			}
		},
		resetPassword: function() {
			var user = firebase.auth().currentUser;
			if (user !== null) {
				user.updatePassword(this.passwordReset).then(() => {
				$('#modal-reset-password').modal('toggle');
					this.passwordReset = "";
					this.infoMessage = "Your password has been reset!";
					$('#modal-info').modal('toggle');
				}).catch((error) => {
					
				//$('#modal-reset-password').modal('toggle');
					this.infoMessage = error.message;
					$('#modal-info').modal('toggle');
					this.passwordReset = "";
				});	
			}		
		}
	},
	mounted: function() {
		var user = firebase.auth().currentUser;
		if (user !== null)
		{
			this.userEmail = user.email;
			firebase.database().ref("users/" + user.uid + "/clients").get().then((data) => {
				var clients = this.fbDBDataToKeyValueObjArray(data);
				clients.forEach((client)=>{
					this.clients.push({name: client.name});
					if (client.value) {
						this.setClient(client.name);	
					}
				})
            });				
		}

		$('#modal-reset-password').on('shown.bs.modal', function () {
			$('#resetPassword').trigger('focus')
		});
	}
}
</script>

<style scoped>
	
.dashboard {
	height: 100%;
	width: 100%;
}

.loggedin-user {
	padding-right: .5rem;
}

.starter-template {
  padding: 16px 0px;
  text-align: center;
  height: 100%;
  width: 100%;
}

.hero {
	width: 100%;
}

.content-home {
	padding-top: 10px;
	text-align: left;
	width: 100%;
}

</style> 